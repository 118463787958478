<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Hydrogen gas is produced by the reaction of
        <chemical-latex content="Al(s)" />
        with
        <chemical-latex content="HCl(aq)" />
        via the reaction:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 Al(s) + 6 HCl(aq) -> 2 AlCl3(aq) + 3 H2(g)" />
      </p>

      <p class="mb-3">
        What volume of hydrogen gas (in L) would be formed at
        <number-value :value="pressure" unit="\text{atm}" />
        and
        <number-value :value="temp" unit="^\circ\text{C}" />
        if
        <number-value :value="mass" unit="\text{g}" />
        of <chemical-latex content="Al" />
        completely reacted with
        <stemble-latex content="$\text{HCl?}$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{V}_\ce{H2}$:"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemCPP1210S4Q4',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
